import React, { useRef } from 'react'
import { Row, Col, Container } from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import cn from 'classnames'
import { GET_PRODUCT_GROUP_LIST } from '../../../data/backend/queries'
import { useQuery } from '@apollo/client'
import qs from 'qs'
import { useStaticQuery, graphql } from 'gatsby'
import { chunkArray } from '@root/src/utils/index'
import styles from './AllBrands.module.scss'
import Loading from '../../site/Loading/Loading'

const ALL_LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

const groupBrands = brands => {
  const grouped = brands.reduce((grouped, brand) => {
    let groupKey = brand.title.split(' ')[0]
    const isLoreal = brand.id.split(' ')[0] === 'LOREAL'
    const isIsay = brand.title.replace(/\W/g, '').toUpperCase() === 'ISAY'
    if (['I', 'Dr.', 'La', 'The', 'Dr', 'Marc'].includes(groupKey) || isLoreal || isIsay) {
      const stripped = brand.title.replace(/\W/g, '').toUpperCase()
      groupKey = stripped.slice(0, 5)
    }

    return {
      ...grouped,
      [groupKey]: grouped[groupKey]
        ? { ...grouped[groupKey], id: [...grouped[groupKey].id, brand.id] }
        : { ...brand, id: [brand.id] }
    }
  }, {})

  return Object.values(grouped)
}

// use the query from the brand page to get all the slugs for the brands

export default ({ title, excludedBrandIds }) => {
  const brandSlugData = useStaticQuery(graphql`
    query {
      allPrismicBrandPage {
        nodes {
          uid
          data {
            brand_id {
              text
            }
          }
        }
      }
    }
  `)

  const brandPages = brandSlugData?.allPrismicBrandPage?.nodes?.map(
    node => {
      return {
        id: node.data.brand_id.text,
        slug: node.uid
      }
    }
  ) ?? []

  const letterRefs = useRef(ALL_LETTERS.map(() => React.createRef()))

  const scrollToElement = (letter) => {
    const index = ALL_LETTERS.indexOf(letter)
    const ref = letterRefs.current[index]
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const { data, loading } = useQuery(GET_PRODUCT_GROUP_LIST, {
    variables: {
      filter: {
        product_group_type: {
          eq: 'brand'
        }
      }
    }
  })

  if (loading) {
    return <Loading />
  } else {
    const brands =
      data?.productGroups?.aggregations?.brands
        ?.filter(brand => brand.count !== 0)
        .map(brand => brand.brand) ?? []
    const brandsWithoutExcluded = brands.filter(
      brand => !excludedBrandIds.includes(brand.id)
    )
    const groupedBrands = groupBrands(brandsWithoutExcluded)

    groupedBrands.forEach((brand) => {
      if (brand.id[0].split(' ')[0] === 'LOREAL') {
        brand.title = 'L\'Oreal'
      }
    })

    return (
      <Container>
        <Row className={cn('mt-4 mb-2', styles.titleContainer)}>
          <Col>
            <h2 className={cn('blue mb-2 pb-1', styles.categoryHeading)}>
              {title}
            </h2>
          </Col>
        </Row>
        <Row className={styles.letters}>
          {ALL_LETTERS.map((letter, index) => (
            <Col key={index}>
              <Link
                to={'/vorumerki/#' + letter}
                onClick={() => scrollToElement(letter)}
              >
                <h4 className='blue'>{letter}</h4>
              </Link>
            </Col>
          ))}
        </Row>
        {ALL_LETTERS.map((letter, index) => {
          const brandsWithLetter = groupedBrands?.filter(
            brand => brand.title[0] === letter
          )
          const chunkedBrands = chunkArray(brandsWithLetter, 4)

          if (chunkedBrands.length === 0) return null

          return (
            <div key={index} ref={letterRefs.current[index]} className={styles.brandContainer}>
              <Row className={styles.brands}>
                <Col className='w-100'>
                  <h2 className='blue'>{letter}</h2>
                </Col>
              </Row>
              {chunkedBrands.map((chunk, chunkIndex) => (
                <Row key={chunkIndex} className={styles.brands}>
                  {chunk.map((brand, brandIndex) => {
                    const brandPagesIds = brandPages.map((page) => page.id)
                    const brandLinkIndex = brandPagesIds.indexOf(brand.id[0])

                    const brandLink = brandLinkIndex !== -1 && brand.id.length === 1
                      ? `/${brandPages[brandLinkIndex].slug}`
                      : `/vorur?${qs.stringify(
                          { b: brand.id },
                          { arrayFormat: 'repeat' }
                        )}`

                    return (
                      <Col key={brandIndex} className={styles.brand}>
                        <Link to={brandLink}>
                          <p>{brand.title}</p>
                        </Link>
                      </Col>
                    )
                  })}
                </Row>
              ))}
            </div>
          )
        })}
      </Container>
    )
  }
}
