import React from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import styles from './Loading.module.scss'

export default ({
  message = '',
  border = false,
  size = message ? 'md' : 'lg'
}) => (
  <div
    className={cn(
      'd-flex justify-content-center align-items-center',
      styles.container,
      { [styles.containerBorder]: border }
    )}
  >
    <FontAwesomeIcon spin icon={faSpinner} size={size} />
    {Boolean(message) && (
      <div className={cn(styles.message, 'ml-2')}>{message}</div>
    )}
  </div>
)
